import React from 'react';
import Layout from '../components/layout';
import SEO from '../components/seo';
import MailChimpForm from '../components/home/mailing-list-form';

const JoinPage = () => (
  <Layout>
    <SEO
      title='Keep In Touch'
      description='Be the first to hear about new developments with the NL Genome Project! Enter your name and email below to sign up for our mailing list.'
    />

    <div className='bg-blue'>
      <div className='container m-auto'>
        <div className='px-8 pt-40 pb-32 m-auto w-full lg:w-2/3'>
          <h2 className='font-sans font-bold text-white text-3xl lg:text-4xl leading-none mb-4'>
            Join our mailing list
          </h2>

          <p className='font-soft font-semibold lg:text-lg text-blue-900 mb-6 leading-snug'>
            Be the first to hear about new developments with the NL Genome Project! Enter your name and email below to sign up for our mailing list.
          </p>

          <MailChimpForm />
        </div>
      </div>
    </div>
  </Layout>
);

export default JoinPage;
